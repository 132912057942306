import React from "react"
import { 
    HeroDarkWithIllustration as Hero,
    ContactSimpleTwoColumns as Contact,
    FeatureAlternatingWithTestimonial,
    FeatureList as Feature,
    StatsSimpleBranded as Stats,
    LogoCloudSimple as LogoCloud,
} from '@domenicosolazzo/site-designsystem';
import Layout from "../Layout"
// import ErrorBoundary from '../../../errors/ErrorHandler'

export default function ProjectLayout(props) {
    // const {
    //     pageContext
    // } = props;
    // const {
    //     pageContent
    // } = pageContext;

    // const {
    //     hero,
    //     featureGroup,
    //     featureList,
    //     logos
    // } = pageContent


    return (
        <Layout>
            <Hero />
            <LogoCloud />
            <FeatureAlternatingWithTestimonial />
            <Feature />
            <Stats />
            <Contact />
        </Layout>
    )
}
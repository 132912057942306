import React from "react"
// import { useStaticQuery, graphql, Link } from "gatsby"
import {
    modelFactory
} from "@domenicosolazzo/site-designsystem"
// import Img, { GatsbyImageSharpFluid } from "gatsby-image"
import {default as ProjectLayout} from "../../components/Layouts/ProjectLayout"
// import ErrorBoundary from "../errors/ErrorHandler"

export default function ProjectTemplatePage(props) {
//     const data = useStaticQuery(graphql`
//         query {
        
//         file(relativePath: { eq: "domenicosolazzo.jpg" }) {
//             childImageSharp {
//             # Specify a fluid image and fragment
//             # The default maxWidth is 800 pixels
//             fluid(quality: 100, maxWidth: 1000) {
//                 ...GatsbyImageSharpFluid
//             }
//             }
//         }
//         }
//     `)
    
//     const img = (
//         <Img
//             fluid={data.file.childImageSharp.fluid}
//             alt="Domenico Solazzo | Developer & Engineering manager"
//             className={""}
//           />
//     )
//     const hero = modelFactory.getModel({
//         type: "hero",
//         options: {
//             heading: {
//                 text: `Let's work together!`,
//                 className: "text-4xl"
//             },
//             subheading: {
//                 text: (
//                     (
//                         <div>
//                             I am an Engineering Manager and Software Engineer! <br /> <br />
//                             👔 Interested in working with me? <br />
//                             🤝 Do you have business collaboration? <br />
//                             🌟 Do you want to hire me?
//                         </div>
//                     )
//                 )
//             },
//             image: {
//                 component: img,
//                 alt: 'Domenico Solazzo | Developer & Engineering manager',
//             }
//         }
//     })
    return(
        <ProjectLayout>
            {props.children}
        </ProjectLayout>
    )
}